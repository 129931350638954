require('../css/connexion.scss');
const $ = require('jquery');
const basePath = 'https://gestionageval.euro-info.fr/';
require('bootstrap');
const Swal = require('sweetalert2');
const crypt = require('js-sha512');

$(document).ready(function(){
    //$('[data-toggle="popover"]').popover();
    $('#FormMotDePasseOublie').hide();
    $('#FormPremiereConnexion').hide();
    $('#FormMotDePasseTemp').hide();

    $("input[name=btn_connexion]").click(function(){
        var username = $("input[name=txt_connexion_login]").val();
        var password = crypt.sha512($("input[name=txt_connexion_motdepasse]").val());
        if(username.trim() == ''){
            Swal.fire({
                icon: 'error',
                title: 'Vous n\'avez pas saisi votre nom d\'utilisateur !'
            });
        }
        else{
            var data = 'username=' + username + "&password=" + password.toUpperCase();
            $.ajax({
                url: basePath + 'connexion',
                data: data,
                dataType: 'json',
                type:'POST',
                success: TryToConnect
            });
        }
    });

    function TryToConnect(data){
        if(data.connected == 0){
            Swal.fire({
                icon: 'success',
                title: 'Première connexion ? Changez votre mot de passe !'
            }).then((result) => { $('#FormConnexion').hide(); $('#FormPremiereConnexion').show(); });
        }
        else if(data.connected == 1){
            Swal.fire({
                icon: 'success',
                title: 'Bienvenue ' + data.prenom + ' !'
            }).then((result) => { document.location.href = basePath + 'index' });
        }
        else if(data.connected == 2){
            Swal.fire({
                icon: 'error',
                title: 'Le login saisi est incorrect.'
            });
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Le mot de passe saisi est incorrect.'
            });
        }
    }

    $("input[name=btn_oubli]").click(function(){
        $('#FormConnexion').hide();
        $('#FormMotDePasseOublie').show();
    });

    $("input[name=btn_annuler]").click(function(){
        $('#FormMotDePasseOublie').hide();
        $('#FormConnexion').show();
    });

    $("input[name=btn_valider]").click(function(){
        var username = $("input[name=txt_oubli_login]").val();
        if(username.trim() == ''){
            Swal.fire({
                icon: 'error',
                title: 'Vous n\'avez pas saisi votre nom d\'utilisateur !'
            });
        }
        else{
            var data = 'username=' + username;
            $.ajax({
                url: basePath + 'mot-de-passe-oublie',
                data: data,
                dataType: 'json',
                type:'POST',
                success: MotDePasseRenouvele
            });
        }
    });

    function MotDePasseRenouvele(data){
        if(data.renouvele){
            Swal.fire({
                icon: 'success',
                title: 'Consultez votre boîte mail afin de réinitialiser votre mot de passe.'
            }).then((result) => { document.location.href = basePath });
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Le login saisi est incorrect.'
            });
        }
    }

    $("input[name=btn_enregistrer]").click(function(){
        var nouveauMotDePasse = $('input[name=txt_nouveau_motdepasse]').val();
        var confirmerMotDePasse = $('input[name=txt_confirmer_motdepasse]').val();
        if(nouveauMotDePasse.trim() == '' || confirmerMotDePasse.trim() == ''){
            Swal.fire({
                icon: 'error',
                title: 'Tous les champs n\'ont pas été remplis.'
            });
        }
        else if(nouveauMotDePasse != confirmerMotDePasse){
            Swal.fire({
                icon: 'error',
                title: 'Les mots de passe ne correspondent pas.'
            });
        }
        else if(nouveauMotDePasse.length < 12){
            Swal.fire({
                icon: 'error',
                title: 'Le mot de passe doit contenir au moins 12 caractères.'
            });
        }
        else{
            var specials = '&~"#\'{([-|`_\\@)]=}+°$£¤*µ%!§:;.,?<>/€';
            var numbers = '0123456789';
            var spec = false;
            var num = false;
            var maj = false;
            var min = false;
            for(var z = 0; z < nouveauMotDePasse.length; z++){
                if(specials.indexOf(nouveauMotDePasse[z]) >= 0) spec = true;
                else if(numbers.indexOf(nouveauMotDePasse[z]) >= 0) num = true;
                else if(nouveauMotDePasse[z] == nouveauMotDePasse[z].toUpperCase()) maj = true;
                else if(nouveauMotDePasse[z] == nouveauMotDePasse[z].toLowerCase()) min = true;
            }
            if(!spec){
                Swal.fire({
                    icon: 'error',
                    title: 'Le mot de passe doit contenir un caractère spécial !'
                });
            }
            else if(!num){
                Swal.fire({
                    icon: 'error',
                    title: 'Le mot de passe doit contenir un chiffre !'
                });
            }
            else if(!maj){
                Swal.fire({
                    icon: 'error',
                    title: 'Le mot de passe doit contenir une majuscule !'
                });
            }
            else if(!min){
                Swal.fire({
                    icon: 'error',
                    title: 'Le mot de passe doit contenir une minuscule !'
                });
            }
            else{
                var data = "password=" + crypt.sha512(nouveauMotDePasse).toUpperCase();
                $.ajax({
                    url: basePath + 'nouveau-mot-de-passe',
                    data: data,
                    dataType: 'json',
                    type:'POST',
                    success: MotDePasseChange
                });
            }
        }
    });

    function MotDePasseChange(data){
        if(data.change){
            Swal.fire({
                icon: 'success',
                title: 'Votre mot de passe a été modifié avec succès !'
            }).then((result) => { document.location.href = basePath });
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Une erreur s\'est produite.\nVeuillez réessayer ultérieurement.'
            });
        }
    }
});